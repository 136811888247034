<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        v-if="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Kategorie</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="userForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="category.name"
                      label="Title"
                      required
                      :rules="requiredRule('Name')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <image-input
                      v-model="category.icon"
                      label="Icon"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                      :disabled="category.hasChildren"
                      v-model="category.parent"
                      label="Elternkategorie"
                      clearable
                      :items="parentCategories"
                      item-value="id"
                      item-text="name"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                      v-model="category.types"
                      :items="types"
                      item-text="label"
                      item-value="id"
                      label="Anwendbar für"
                      multiple
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Schließen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveCategory"
          >
            {{ category.id ? 'Speichern' : 'Hinzufügen'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>
<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from '@/components/admin/Snackbar'
import {mapState} from "vuex";
import ImageInput from "@/components/common/ImageInput";
import {categoryTypes as types} from "@/utils/common";
export default {
  name: 'CategoryEditDialog',
  components: { Snackbar, ConfirmDialog, ImageInput },
  data: () => ({
    categories: [],
    showDialog: false,
    category: {},
    snackbarSuccess: {
      text: 'Kategorie erfolgreich gespeichert',
      icon: 'mdi-check-circle',
      color: 'success'
    },
    menu: false,
  }),
  methods: {
    show (category, clusterId, categories) {
      this.category = category ? category : { cluster: clusterId, types: [] }
      this.categories = categories
      if(category?.id) {
        this.categories = this.categories.filter(cat => cat.id !== category.id)
      }
      this.showDialog = true
    },
    close () {
      this.showDialog = false
      this.category = {}
      this.$refs.userForm.resetValidation()
    },
    async saveCategory () {
      if (!this.$refs.userForm.validate()) {
        return
      }
      this.category.icon = this.category.icon?.id
      if (this.category.id) {
        APIService.updateCategory(this.category).then((response) => {
          console.log("Update Response", response, this.categories)
          this.$emit('changed')
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      } else {
        console.log("Category", this.category)
          APIService.createCategory(this.category).then((category) => {
          console.log("Save Response", category, this.categories)
            this.$emit('changed')
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      }
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
  },
  computed: {
    ...mapState(['user']),
    parentCategories() {
      return this.categories.filter(cat => !cat.parent)
    },
    types() { return types },
  },
  watch: {
    "user.email": function() {
      this.isEmailTaken = false
    }
  },
}
</script>

<style scoped>

</style>
